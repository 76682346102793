import * as React from "react";

import Layout from "../components/Layout";
import SEO from "../components/Seo";
import { css } from "@emotion/core";

const ThanksPage = ({ data }) => {
  return (
    <Layout>
      <SEO title={"Thank you!"} />
      <div
        className={"text-center w-90 max-w-screen"}
        css={css`
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        `}
      >
        <h1 className={"text-3xl md:text-4xl font-bold leading-tight"}>
          Thank you!
        </h1>
        <p>
          You should receive an email shortly that you must confirm in order to
          receive your gift card.
        </p>
      </div>
    </Layout>
  );
};

export default ThanksPage;
